<template>
  <div class="row pt-1" v-if="berechtigungen.m_ressourcen.read">
    <div class="col-lg-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie">
          <div class="header">
            <span>{{ $t("global.surveys") }}</span>
          </div>
          <div class="body">
            <div class="row">
              <div :class="kachelCol">
                <div class="tile-frame h-2" :class="kachelStyle">
                  <router-link :to="{ name: 'questionaire-liste' }">
                    <div class="tile bg-light-brown inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-clipboard-question"
                          transform="shrink-2"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.surveys") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'

export default {
  name: "StartseiteQS",
  components: {},
  mixins: [],
  props: {
    kachelStyle: { type: String },
    kachelCol: { type: String },
    kachelKleinStyle: { type: String },
    kachelKleinCol: { type: String },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
